import { FC, useCallback, useState } from "react";
import {
    GoogleMap,
    LoadScript,
    DirectionsService,
    DirectionsRenderer,
    MarkerF,
} from "@react-google-maps/api";
import MapPin from "../../assets/svg/MapPin.svg";
import MapLocation from "../../assets/svg/MapLocation.svg";

type Props = {
    startLocation: {lat: number; lng: number};
    endLocation: {lat: number; lng: number};
    transitLocations: Array<{lat: number; lng: number}>;
    centerPosition: {lat: number; lng: number};
};

/// ルート表示用
export const MapRoute: FC<Props> = (props: Props) => {
    // todo: apply implementation whether including transit-points
    const [travelMode, setTravelMode] = useState(null);

    const [currentDirection, setCurrentDirection] = useState(null);
    const directionsCallback = useCallback(
        (googleResponse) => {
            if (googleResponse) {
                if (currentDirection) {
                    if (
                        googleResponse.status === "OK" &&
                        googleResponse.geocoded_waypoints.length !==
                            currentDirection.geocoded_waypoints.length
                    ) {
                        setCurrentDirection(googleResponse);
                    }
                } else {
                    if (googleResponse.status === "OK") {
                        setCurrentDirection(googleResponse);
                    }
                }
            }
        },
        [currentDirection]
    );

    const onLoad = useCallback(
        async function callback() {
            // Get directions
            const google = window.google;
            const directionsService = new google.maps.DirectionsService();

            directionsService.route(
                {
                    origin: new google.maps.LatLng(props.startLocation.lat, props.startLocation.lng),
                    destination: new google.maps.LatLng(props.endLocation.lat, props.endLocation.lng),
                    travelMode: google.maps.TravelMode.WALKING,
                },
                () => {
                    /// loadされてから`google`を読み込まないとエラーが生じる
                    setTravelMode(google.maps.TravelMode.WALKING);
                }
            );
        },
        [
            currentDirection,
            props.startLocation,
            props.endLocation,
            props.transitLocations,
        ]
    );

    return (
        <LoadScript
            googleMapsApiKey={"AIzaSyCm8okcrIiqutldDxWq6TZxvFBw-DcsjM8"}
            onLoad={onLoad}
        >
            <GoogleMap
                center={props.centerPosition}
                zoom={16}
                mapContainerStyle={{
                    width: "100%",
                    aspectRatio: "0.7",
                }}
                options={{
                    disableDefaultUI: true,
                }}
            >
                <>
                    {travelMode !== null && (
                        <DirectionsService
                            options={{
                                origin: new google.maps.LatLng(props.startLocation.lat, props.startLocation.lng),
                                destination: new google.maps.LatLng(props.endLocation.lat, props.endLocation.lng),
                                travelMode: travelMode,
                                // 走行モードを指定する。今回は自動車に設定
                                optimizeWaypoints: false,
                                // 経由地の順序を最適化する場合はtrueに設定する
                                waypoints: props.transitLocations.map((t) => {
                                    return {location: new google.maps.LatLng(t.lat, t.lng)};
                                }),
                            }}
                            callback={directionsCallback}
                        />
                    )}
                    {currentDirection !== null && (
                        <DirectionsRenderer
                            options={{
                                directions: currentDirection,
                                markerOptions: {
                                    icon: MapPin,
                                },
                                polylineOptions: {
                                    strokeOpacity: 0,
                                    strokeColor: "#9C27B0",
                                    strokeWeight: 2,
                                    icons: [
                                        {
                                            icon: {
                                                path: "M 0 0 L 0 0.8",
                                                fillColor: "#9C27B0",
                                                scale: 5,

                                                strokeOpacity: 1,
                                            },
                                            repeat: "16px",
                                            offset: "0",
                                        },
                                    ],
                                },
                            }}
                        />
                        // DirectionsServiceのAPI検索の結果としてcurrenctDirectionがあれば、その結果をDirectionsRendererで表示する。
                        // 予めルート情報を持っていれば、DirecitonsServiceでAPIコールする必要はない。
                    )}
                    {travelMode !== null &&<MarkerF position={new google.maps.LatLng(props.startLocation.lat, props.startLocation.lng)} icon={MapLocation} />}
                </>
            </GoogleMap>
        </LoadScript>
    );
};
