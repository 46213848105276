import { Poi, Route } from "../interfaces";
import { APIClient } from "./core";

export class PoIAPI extends APIClient {
    async getPois(group: number): Promise<{pois: Poi[]}> {
        const res = await this.fetch(`/public/poi?group=${group}`, {method : "GET"});
        if (res.status === 200) {
            const json = res.json();
            return json;
        }
        return undefined;
    }

    async getTour(group: number, start: string, end: string, interested?: number[]): Promise<{routes: Route[]}> {
        
        const res = await this.fetch(`/public/tour?group=${group}&start=${start}&end=${end}${(interested[0] ? `&poi1=${interested[0]}${(interested[1]) ? `&poi2=${interested[1]}${(interested[2]) ? `&poi3=${interested[2]}`: ""}`: ""}` : "")}`, {method : "GET"});
        if (res.status === 200) {
            const json = res.json();
            return json;
        }
        return undefined;
    }
}