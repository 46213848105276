import { API_ENDPOINT } from "../environments";

export class APIClient {
    endopoint: string;
    constructor() {
        this.endopoint = API_ENDPOINT;
    }
    fetch(path: string, init?: RequestInit): Promise<Response> {
        const modifiedInit: RequestInit = init;
        modifiedInit.headers = { "content-type": "application/json" };
        return fetch(this.endopoint + path, modifiedInit);
    }
}