import { FC } from "react";
import classes from "./style.module.css";

type Props = {
    path: string;
    alt?: string;
    annotation: string;
};

/// 地名付き画像
export const AnnotatedImage: FC<Props> = (props) => {
    return (
        <div className={classes.annotationWrapper}>
            {
                props.path ? 
                    <img src={props.path} alt={props.alt} className={classes.image} />
                    :
                    <div className={classes.image}></div>
            }
            <div className={classes.annotation}>
                <p className={classes.annotationText}>{props.annotation}</p>
            </div>
        </div>
    );
};
