import { FC, useState } from "react";
import { Header } from "../../header/header";
import classes from "./style.module.css";
import { SpotSelect } from "../../select/select-spots";
import { SelectHour } from "../../select/select-hour";
import { SelectMinute } from "../../select/select-minute";
import { Button } from "../../button/button";
import { Poi } from "../../../interfaces";

// フォームのデータを格納する型
export type TripFormData = {
    startTime: string;
    endTime: string;
};

type Props = {
    pois: Poi[];
    onCalc: (start: string, end: string, interested?: number[]) => void;
};

export const SearchSportsPage: FC<Props> = (props) => {
    const [firstInterestedID, setFirstInterestedID] = useState<number>(0);
    const [secondInterestedID, setSecondInterestedID] = useState<number>(0);
    const [thirdInterestedID, setThirdInterestedID] = useState<number>(0);
    const [startHour, setStartHour] = useState<number>(13);
    const [startMinuit, setStartMinuit] = useState<number>(0);
    const [endHour, setEndHour] = useState<number>(17);
    const [endMinuit, setEndMinuit] = useState<number>(0);
    
    return (
        <>
            <Header />
            <form className={classes.body}>
                <div className={classes.selectElement}>
                    <div className={classes.titleWrapper}>
                        <p className={classes.title}>ツアー開始時間</p>
                        <p className={classes.requiredMark}>*</p>
                    </div>
                    <div className={classes.selectTime}>
                        <SelectHour
                            value={startHour}
                            onChange={(value) => setStartHour(value)}
                        />
                        <p className={classes.hour}>時</p>
                        <SelectMinute
                            value={startMinuit}
                            onChange={(value) => setStartMinuit(value)}
                        />
                        <p className={classes.minute}>分</p>
                    </div>
                </div>
                <div className={classes.selectElement}>
                    <div className={classes.titleWrapper}>
                        <p className={classes.title}>ツアー終了時間</p>
                        <p className={classes.requiredMark}>*</p>
                    </div>
                    <div className={classes.selectTime}>
                        <SelectHour
                            value={endHour}
                            onChange={(value) => setEndHour(value)}
                        />
                        <p className={classes.hour}>時</p>
                        <SelectMinute
                            value={endMinuit}
                            onChange={(value) => setEndMinuit(value)}
                        />
                        <p className={classes.minute}>分</p>
                    </div>
                </div>
                <div className={classes.selectElement}>
                    <div className={classes.titleWrapper}>
                        <p className={classes.title}>
                            行きたいスポット(3つまで)
                        </p>
                    </div>
                    <div className={classes.selectSpotsWrapper}>
                        <div className={classes.selectSpot}>
                            <SpotSelect pois={props.pois} id={firstInterestedID} onChange={(id) => {
                                if (id == 0) {
                                    setFirstInterestedID(id);
                                    setSecondInterestedID(0);
                                    setThirdInterestedID(0);
                                } else {
                                    setFirstInterestedID(id);
                                }
                                }} />
                        </div>
                        {firstInterestedID != 0 && <div className={classes.selectSpot}>
                            <SpotSelect id={secondInterestedID} pois={props.pois.filter((poi) => poi.id != firstInterestedID)} onChange={(id) => {
                                if (id == 0) {
                                    setSecondInterestedID(id);
                                    setThirdInterestedID(0);
                                } else {
                                    setSecondInterestedID(id);
                                }
                                }} />
                        </div>}
                        {secondInterestedID != 0 && firstInterestedID != 0 && <div className={classes.selectSpot}>
                            <SpotSelect id={thirdInterestedID} pois={props.pois.filter((poi) => poi.id != firstInterestedID && poi.id != secondInterestedID)} onChange={(id) => setThirdInterestedID(id)} />
                        </div>}
                    </div>
                </div>
                <div className={classes.lowPositionElementWrapper}>
                    <Button
                        text="ツアーをAIに任せてみる"
                        onClick={() => props.onCalc(
                                String(startHour).padStart(2, '0') + ":" + String(startMinuit).padStart(2, '0'),
                                String(endHour).padStart(2, '0') + ":" + String(endMinuit).padStart(2, '0'),
                                [firstInterestedID, secondInterestedID, thirdInterestedID]
                            )}
                    />
                </div>
            </form>
        </>
    );
};
