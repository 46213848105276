import { FC, useCallback, useState } from "react";
import {
    GoogleMap,
    LoadScript,
    DirectionsService,
    DirectionsRenderer,
} from "@react-google-maps/api";
import { Header } from "../header/header";
import { AnnotatedImage } from "../img-annotated";

export const RecommendRoutePage: FC = () => {
    const startLocation = {
        lat: 43.035718552,
        lng: 141.46244422,
    };
    const endLocation = {
        lat: 43.135718552,
        lng: 141.46244422,
    };
    const transitLocations = [
        {
            location: {
                lat: 43.05718552,
                lng: 141.4624442,
            },
        },
    ];
    /// todo: 出発、目的地を使ってcenterを計算
    const centerPosition = {
        lat: 43.135718552,
        lng: 141.46244422,
    };
    // todo:
    const [travelMode, setTravelMode] = useState(null);

    const [currentDirection, setCurrentDirection] = useState(null);
    const directionsCallback = useCallback(
        (googleResponse) => {
            if (googleResponse) {
                if (currentDirection) {
                    if (
                        googleResponse.status === "OK" &&
                        googleResponse.geocoded_waypoints.length !==
                            currentDirection.geocoded_waypoints.length
                    ) {
                        console.log("ルートが変更されたのでstateを更新する");
                        setCurrentDirection(googleResponse);
                    } else {
                        console.log("前回と同じルートのためstateを更新しない");
                    }
                } else {
                    if (googleResponse.status === "OK") {
                        console.log(
                            "初めてルートが設定されたため、stateを更新する"
                        );
                        setCurrentDirection(googleResponse);
                    } else {
                        console.log("前回と同じルートのためstateを更新しない");
                    }
                }
            }
        },
        [currentDirection]
    );

    const onLoad = useCallback(
        async function callback() {
            // Get directions
            const google = window.google;
            const directionsService = new google.maps.DirectionsService();

            directionsService.route(
                {
                    origin: startLocation,
                    destination: endLocation,
                    travelMode: google.maps.TravelMode.WALKING,
                },
                () => {
                    /// loadされてから`google`を読み込まないとエラーが生じる
                    setTravelMode(google.maps.TravelMode.WALKING);
                }
            );
        },
        [currentDirection, startLocation, endLocation, transitLocations]
    );

    return (
        <>
            <Header />
            <LoadScript
                googleMapsApiKey={"AIzaSyCm8okcrIiqutldDxWq6TZxvFBw-DcsjM8"}
                onLoad={onLoad}
            >
                <GoogleMap
                    center={centerPosition}
                    zoom={16}
                    mapContainerStyle={{ width: "100%", aspectRatio: "16 / 9" }}
                >
                    <>
                        {travelMode !== null && (
                            <DirectionsService
                                options={{
                                    origin: startLocation,
                                    destination: endLocation,
                                    travelMode: travelMode,
                                    // 走行モードを指定する。今回は自動車に設定
                                    optimizeWaypoints: true,
                                    // 経由地の順序を最適化する場合はtrueに設定する
                                    waypoints: transitLocations,
                                }}
                                callback={directionsCallback}
                            />
                        )}
                        {currentDirection !== null && (
                            <DirectionsRenderer
                                options={{
                                    directions: currentDirection,
                                }}
                            />
                            // DirectionsServiceのAPI検索の結果としてcurrenctDirectionがあれば、その結果をDirectionsRendererで表示する。
                            // 予めルート情報を持っていれば、DirecitonsServiceでAPIコールする必要はない。
                        )}
                    </>
                </GoogleMap>
                <AnnotatedImage
                    path="https://gururinkansai.com/P11102791.jpg"
                    annotation="金閣寺"
                />
            </LoadScript>
            {/*<RouteOverview />*/}
        </>
    );
};
