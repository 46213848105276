import { FC } from "react";
import classes from "./button.module.css";

type Props = {
    onClick: () => void;
    text: string;
};

export const Button: FC<Props> = (props: Props) => {
    return (
        <div className={classes.createButtonWrap}>
            <div
                role="button"
                tabIndex={0}
                onClick={props.onClick}
                className={classes.createButton}
            >
                <div className={classes.text}>{props.text}</div>
            </div>
        </div>
    );
};
