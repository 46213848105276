import { FC } from "react";
import { Header } from "../../header/header";
import classes from "./style.module.css";
import { RouteOverview } from "./route-overview";
import ArrowLeftPrimaryColor from "../../../assets/svg/ArrowLeftPrimaryColor.svg";
import { Poi, Route } from "../../../interfaces";
import { useNavigate } from "react-router-dom";

type Props = {
    routes: Route[];
    pois: Poi[];
    onClick: (index: number) => void;
}
export const ResultOverviewPage: FC<Props> = (props) => {
    const navigate = useNavigate();
    if (!props.pois) {
        navigate("/");
    }
    if (!props.routes) {
        navigate("/tour");
    }
    return (
        <>
            <Header />
            <div>
                <ul className={classes.routes}>
                    <li className={classes.route}>
                        <RouteOverview
                            name="ルート1"
                            pois={props.pois}
                            onClick={() => props.onClick(0)}
                            route={props.routes[0]}
                            />
                    </li>
                    <li className={classes.route}>
                        <RouteOverview
                            name="ルート2"
                            pois={props.pois}
                            onClick={() => props.onClick(1)}
                            route={props.routes[1]}
                            />
                    </li>
                    <li className={classes.route}>
                        <RouteOverview
                            name="ルート3"
                            pois={props.pois}
                            onClick={() => props.onClick(2)}
                            route={props.routes[2]}
                            />
                    </li>
                </ul>
            </div>
            <div className={classes.back} onClick={() => navigate("/tour")} tabIndex={0} role="button">
                <img src={ArrowLeftPrimaryColor} />
                <p className={classes.backText}>条件を変えてみる</p>
            </div>
        </>
    );
};
