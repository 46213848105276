import { FC, ReactNode } from "react";
import { Header } from "../../header/header";
import { MapRoute } from "../../map_route";
import { AnnotatedImage } from "../../img-annotated";
import classes from "./style.module.css";
import ArrowRight from "../../../assets/svg/ArrowRight.svg";
import ArrowLeftPrimaryColor from "../../../assets/svg/ArrowLeftPrimaryColor.svg";
import { useNavigate } from "react-router-dom";
import { Poi, Route } from "../../../interfaces";

type Props = {
    route: Route;
    pois: Poi[];
    name: string;
}
export const ResultDetailPage: FC<Props> = (props) => {
    const navigate = useNavigate();
    const startLocation = {
        lat: props.pois.find((poi) => poi.id === props.route.pois[0].poi_id).latitude,
        lng: props.pois.find((poi) => poi.id === props.route.pois[0].poi_id).longitude,
    };
    const endLocation = {
        lat: props.pois.find((poi) => poi.id === props.route.pois[0].poi_id).latitude,
        lng: props.pois.find((poi) => poi.id === props.route.pois[0].poi_id).longitude,
    };
    const transitLocations = props.route.pois.filter((poi) => poi.poi_id !== props.route.pois[0].poi_id).map((p) => {
        return {
            lat: props.pois.find((poi) => poi.id === p.poi_id).latitude,
            lng: props.pois.find((poi) => poi.id === p.poi_id).longitude,
        };
    });
    /// todo: 出発、目的地を使ってcenterを計算
    const centerPosition = {
        lat: props.pois.find((poi) => poi.id === props.route.pois[0].poi_id).latitude,
        lng: props.pois.find((poi) => poi.id === props.route.pois[0].poi_id).longitude,
    };
    const spots = props.route.pois.map((poi) => {
        return {
            path: props.pois.find((p) => p.id === poi.poi_id).image,
            annotation: props.pois.find((p) => p.id === poi.poi_id).name,
        };
    });
    
    return (
        <>
            <Header />
            <div className={classes.body}>
                <MapRoute
                    startLocation={startLocation}
                    endLocation={endLocation}
                    transitLocations={transitLocations}
                    centerPosition={centerPosition}
                />
                <div className={classes.wrapper}>
                    <p className={classes.title}>{props.name}</p>
                    <div className={classes.spots}>
                        {spots
                            .map((spot, i) => {
                                return (
                                    <div
                                        key={i /** todo */}
                                        className={classes.imageWrapper}
                                    >
                                        <AnnotatedImage
                                            path={spot.path}
                                            annotation={spot.annotation}
                                        />
                                    </div>
                                );
                            })
                            .reduce<ReactNode[]>((prev, curr) => {
                                return [
                                    ...prev,
                                    curr,
                                    <img
                                        src={ArrowRight}
                                        key={"separator"+curr.key}
                                        className={classes.rightArrow}
                                    />,
                                ];
                            }, [])
                            .slice(0, -1)}
                    </div>
                </div>
                <div className={classes.seemore} onClick={() => navigate("/result")} tabIndex={0} role="button">
                    <img src={ArrowLeftPrimaryColor} />
                    <p className={classes.seemoreText}>他のルートも見てみる</p>
                </div>
            </div>
        </>
    );
};
