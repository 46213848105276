import { FC } from "react";
import { Header } from "../../header/header";
import { MapMarks } from "../../map_marks";
import classes from "./style.module.css";
import PinIcon from "../../../assets/svg/PinIcon.svg";
import { Button } from "../../button/button";
import { AnnotatedImage } from "../../img-annotated";
import { Poi } from "../../../interfaces";
import { useNavigate } from "react-router-dom";

type Props = {
    pois: Poi[] | undefined;
};

export const IndexPage: FC<Props> = (props) => {
    const navigate = useNavigate();
    
    if (!props.pois) {
        return;
    }
    return (
        <>
            <Header />
            {props.pois !== undefined && <MapMarks pois={props.pois}/>}
            <div className={classes.spots}>
                <div className={classes.titleline}>
                    <img
                        src={PinIcon}
                        alt=""
                        height="18px"
                        className={classes.logonavi}
                    />
                    <p className={classes.title}>近辺のスポット</p>
                </div>
                <div className={classes.locationList}>
                    {" "}
                    <div className={classes.spotsList}>
                            {
                                (props.pois) ? props.pois?.filter((poi) => !poi.basement_flag).map((spot) => {
                                    return (
                                        <div
                                            key={spot.id}
                                            className={classes.imageWrapper}
                                        >
                                            <AnnotatedImage
                                                path={spot.image}
                                                annotation={spot.name}
                                            />
                                        </div>
                                    );
                                }) : <></>
                            }
                    </div>
                </div>
                <div>
                    <Button
                        text="ツアーをAIに任せてみる"
                        onClick={() => navigate("/tour")}
                    />
                </div>
            </div>
        </>
    );
};
