import { FC, useCallback } from "react";
import { GoogleMap, LoadScript, MarkerF } from "@react-google-maps/api";
import MapPin from "../../assets/svg/MapPin.svg";
import MapLocation from "../../assets/svg/MapLocation.svg";
import { Poi } from "../../interfaces";

type Props = {
    pois: Poi[] | undefined;
};
// 経路付きマップ表示用コンポーネント
export const MapMarks: FC<Props> = (props) => {
    if (props.pois === undefined) return;
    /// 現在地を緯度経度で格納
    const nowLocation = {
        lat: props.pois.find((poi) => poi.basement_flag)?.latitude,
        lng: props.pois.find((poi) => poi.basement_flag)?.longitude,
    };

    const markPositions = props.pois.filter((poi) => !poi.basement_flag).map((poi) => {
        return ({
            location: {
                lat: poi.latitude,
                lng: poi.longitude,
            }
        });
    });

    const center = {
        lat: props.pois.find((poi) => poi.basement_flag)?.latitude,
        lng: props.pois.find((poi) => poi.basement_flag)?.longitude,
    };

    const markers = markPositions.map((pos, i) => {
        return (
            <li key={i}>
                <MarkerF position={pos.location} icon={MapPin} />
            </li>
        );
    });

    return (
        <LoadScript
            googleMapsApiKey={"AIzaSyCm8okcrIiqutldDxWq6TZxvFBw-DcsjM8"}
        >
            <GoogleMap
                center={center}
                zoom={11}
                mapContainerStyle={{
                    width: "100%",
                    aspectRatio: "0.7",
                }}
                options={{
                    disableDefaultUI: true,
                }}
            >
                {markers}
                <MarkerF position={nowLocation} icon={MapLocation} />
            </GoogleMap>
        </LoadScript>
    );
};
