import type { FC } from "react";
import type React from "react";
import classes from "./select-spots.module.css";
import { Poi } from "../../interfaces";

export const UntilEnum = {
    quarterDay: 6,
    halfDay: 12,
    oneDay: 24,
    twoDays: 48,
} as const;

export type UntilEnum = (typeof UntilEnum)[keyof typeof UntilEnum];

type Props = {
    pois: Poi[];
    onChange: (id: number) => void;
    id: number;
};

export const SpotSelect: FC<Props> = (props) => {
    return (
        <div className={classes.selectBoxWrapper}>
            <span className={classes.selectBoxBefore} />
            <select
                className={classes.selectBox}
                value={props.id}
                onChange={(e) => props.onChange(Number(e.target.value))}
            >
                <option value="0">選択してください</option>
                {props.pois.map((poi) => {
                    return (
                        <option value={poi.id} key={poi.id}>
                            {poi.name}
                        </option>
                    );
                })}
            </select>
        </div>
    );
};
