import { Route, Routes, useNavigate } from "react-router-dom";
import { RecommendRoutePage } from "./components/pages/recommend_routes";
import "./styles/global.css";
import { IndexPage } from "./components/pages/index";
import { ResultOverviewPage } from "./components/pages/result-overview";
import { ResultDetailPage } from "./components/pages/result-detail";
import { SearchSportsPage } from "./components/pages/search-spots";
import React from "react";
import { Poi, Route as Tour } from "./interfaces";
import { PoIAPI } from "./api/poi";

const poiApi = new PoIAPI();

function App() {
    const navigate = useNavigate();
    const [pois, setPois] = React.useState<Poi[]>([]);
    const [tours, setTours] = React.useState<Tour[]>([]);
    const [route, setRoute] = React.useState<number>(0);
    React.useEffect(() => {
        (async () => {
            setPois((await poiApi.getPois(2)).pois);
        })();
    }, []);

    return (
        <div className="width: 100%">
            <Routes>
                <Route path="/" element={<IndexPage pois={pois} />} />
                <Route path="/test" element={<RecommendRoutePage />} />
                <Route
                    path="/result"
                    element={<ResultOverviewPage
                        pois={pois}
                        routes={tours}
                        onClick={(index) => {
                            setRoute(index);
                            navigate("/result-detail");
                        }}
                        />}
                />
                <Route path="/result-detail" element={<ResultDetailPage route={tours[route]} pois={pois} name={`ルード${route+1}`}/>} />
                <Route path="/tour" element={<SearchSportsPage pois={pois} onCalc={(start, end, interested) => {
                    (async () => {
                        const res = await poiApi.getTour(2, start, end, interested);
                        setTours(res.routes);
                        console.log(res.routes);
                        navigate("/result");
                    })();
                    
                }} />} />
            </Routes>
        </div>
    );
}

export default App;