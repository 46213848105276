import { FC } from "react";
import classes from "./header.module.css";
import Logo from "../../assets/svg/logo.svg";
import Logonavi from "../../assets/svg/logotype.svg";
import { useNavigate } from "react-router-dom";


export const Header: FC = () => {
    const navigate = useNavigate();
    return (
        <>
            <div 
                className={classes.pageTitle}
                onClick={() => navigate("/")}
                role="button"
                tabIndex={0}>
                <div className={classes.pageTitleInner}>
                    <div
                        className={classes.pageCancelButton}
                    >
                        <img src={Logo} alt="" />
                    </div>
                    <div
                        className={`${classes.pageTitleContentWrapper} ${classes.withButton}`}
                    >
                        <img
                            src={Logonavi}
                            alt=""
                            height="20px"
                            className={classes.logonavi}
                        />
                    </div>
                </div>
            </div>
            <div className={classes.underTitle}></div>
        </>
    );
};
