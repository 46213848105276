import { FC, ReactNode } from "react";
import classes from "./route-overview.module.css";
import ArrowRightPrimaryColor from "../../../assets/svg/ArrowRightPrimaryColor.svg";
import { AnnotatedImage } from "../../img-annotated";
import ArrowRight from "../../../assets/svg/ArrowRight.svg";
import { Poi, Route } from "../../../interfaces";

type Props = {
    name: string;
    onClick: () => void;
    route: Route;
    pois: Poi[];
};

/// 1つのルート概要のコンポーネント
export const RouteOverview: FC<Props> = (props) => {
    const spots = props.route.pois.map((poi) => {
        return {
            path: props.pois.find((p) => p.id === poi.poi_id).image,
            annotation: props.pois.find((p) => p.id === poi.poi_id).name,
        };
    });
    return (
        <div className={classes.wrapper}>
            <div className={classes.head}>
                <p className={classes.name}>{props.name}</p>
                <div className={classes.seemore} onClick={props.onClick} tabIndex={0} role="button">
                    <p className={classes.seemoreText}>
                        このルートをマップで見る
                    </p>
                    <img src={ArrowRightPrimaryColor} />
                </div>
            </div>
            <div className={classes.spots}>
                {spots
                    .map((spot, i) => {
                        return (
                            <div
                                key={"route"+i}
                                className={classes.imageWrapper}
                            >
                                <AnnotatedImage
                                    path={spot.path}
                                    annotation={spot.annotation}
                                />
                            </div>
                        );
                    })
                    .reduce<ReactNode[]>((prev, curr) => {
                        return [
                            ...prev,
                            curr,
                            <img
                                src={ArrowRight}
                                key={"separator"+prev.findIndex+curr.key}
                                className={classes.rightArrow}
                            />,
                        ];
                    }, [])
                    .slice(0, -1)}
            </div>
            <div className={classes.expectedRate}>
                <p>期待満足度 {props.route.score}</p>
            </div>
        </div>
    );
};
