import type { FC } from "react";
import type React from "react";
import classes from "./select-minute.module.css";

type Props = {
    value?: number;
    onChange: (value: number) => void;
};

export const SelectMinute: FC<Props> = (props) => {
    return (
        <div className={classes.selectBoxWrapper}>
            <span className={classes.selectBoxBefore} />
            <select
                className={classes.selectBox}
                value={props.value ? props.value : undefined}
                onChange={(e) => props.onChange(Number(e.target.value))}
                defaultValue={1}
            >
                {Array.from({ length: 12 }, (_, i) => i * 5).map((minute) => {
                    return (
                        <option value={minute} key={minute}>
                            {minute}
                        </option>
                    );
                })}
            </select>
        </div>
    );
};
