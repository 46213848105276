import type { FC } from "react";
import type React from "react";
import classes from "./select-hour.module.css";

type Props = {
    value: number;
    onChange: (value: number) => void;
};

export const SelectHour: FC<Props> = (props) => {
    return (
        <div className={classes.selectBoxWrapper}>
            <span className={classes.selectBoxBefore} />
            <select
                className={classes.selectBox}
                value={props.value}
                onChange={(e) => props.onChange(Number(e.target.value))}
            >
                {Array.from({ length: 24 }, (_, i) => i + 1).map((hour) => {
                    return (
                        <option value={hour} key={hour}>
                            {hour}
                        </option>
                    );
                })}
            </select>
        </div>
    );
};
